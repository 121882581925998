import React, { Component } from 'react';
import Header from './components/Header';
import WeddingEvents from './components/WeddingEvents';
import Gallery from './components/Gallery';
import Registry from './components/Registry';
import PartyIntro from './components/PartyIntro';

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Header/>
        <WeddingEvents/>
        <Gallery/>
        <Registry/>
        <PartyIntro/>
      </React.Fragment>
    );
  }
}

export default Home;
