import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import { Layout } from './Layout'

const Styles = styled.div`

  .navbar-brand, .navbar-nav .nav-link {
    color: #bbb;

    &:hover {
      color: white;
    }
  }

`;

export const Navigation = () => (
  <Styles>
    <Navbar fixed="top" bg="dark" className="navbar-dark" expand="lg">
      <Layout>
        <Navbar.Brand href="/" className="font-secondary">A &amp; K</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link href="/schedule">Schedule</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link href="/travel">Rooms &amp; Travel</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link href="/registry">Registry</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link href="/party">Wedding Party</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link href="/explore">Things To Do</Nav.Link></Nav.Item>
            {/* <Nav.Item><Nav.Link href="/rsvp">RSVP</Nav.Link></Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </Layout>
    </Navbar>    
  </Styles>
)