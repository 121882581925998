import React, { Component } from 'react';
import {Layout} from './components/Layout'
import './bootstrap.css';
import './Fonts.css';
import './Wedding.css';
import './App.css';


export default class Schedule extends Component {
  render() {
    return (
      <Layout>
      <div className="mb-5 pt-5 text-center">
        <h1 className="font-secondary text-primary">Schedule</h1>
        <div className="container mt-5 mb-5">

          <div className="row ">
            <div className="col-sm-6 offset-sm-3 pb-5 border-bottom mb-5">
              <h2>Rehearsal Dinner</h2>
              <p>The wedding party and their significant others and parents of the bride and groom only.</p>
              <h5>Friday, September 24th, 2021<br/>6:00PM</h5>
              <p>Catawba Island Club<br/>
                4235 East Beach Club Road<br/>
                Port Clinton, OH 43452</p>
            </div>
          </div>

          
          <div className="row">
            <div className="col-sm-6 offset-sm-3 pb-5 border-bottom mb-5">
              <h2>Ceremony</h2>
              <h5>Saturday, September 25th, 2021<br/>3:30PM</h5>
              <p>Catawba Island Club<br/>
                4235 East Beach Club Road<br/>
                Port Clinton, OH 43452</p>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-6 offset-sm-3 pb-5 border-bottom mb-5">
              <h2>Reception</h2>
              <h5>Saturday, September 25th, 2021<br/>5:30PM</h5>
              <p>Catawba Island Club<br/>
                4235 East Beach Club Road<br/>
                Port Clinton, OH 43452</p>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-6 offset-sm-3 pb-5 mb-5">
              <h2>After Party</h2>
              <h5>Saturday, September 25th, 2021<br/>Midnight</h5>
              <p>Catawba Island Brewing Company<br/>
                2330 E Harbor Rd<br/>
                Port Clinton, OH 43452</p>
            </div>
          </div>


        </div>
       
      </div>
    </Layout>
    )
  }
}
