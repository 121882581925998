import React, { Component } from 'react'

export default class Registry extends Component {
  render() {
    return (
      <section id="gift" className=" bg-primary">
        <div className="container spacer-double-lg">
          <div className="row justify-content-between z-index-3 position-relative align-items-center">
            <div className="col-md-6 mb-3 mb-md-0">
              <h1 className="display-4 text-white font-secondary">Bridal Shower Registry</h1>
              <p className="lead text-white">Experiences and homegoods we need to start our life.</p>
              <a href="/registry" class="btn btn-outline-secondary text-white btn-wide-sm btn-sm  scroll">View Registry</a>
            </div>
          </div>
        </div>
        <div className="curved-decoration z-index-3">
          <svg width="100%" height="100%" className="bg-white-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2560 168.6227" enableBackground="new 0 0 2560 168.6227" xmlSpace="preserve">
            <g />
            <g>
              <path d="M0,0c0,0,219.6543,165.951,730.788,124.0771c383.3156-31.4028,827.2139-96.9514,1244.7139-96.9514
    c212.5106,0,438.9999,3.5,584.4982,1.5844v139.9126H0V0z" />
            </g>
          </svg>
        </div>
      </section>
    )
  }
}
