import React, { Component } from 'react';
import {Layout} from './components/Layout'
import './bootstrap.css';
import './Fonts.css';
import './Wedding.css';
import './App.css';


export default class Registry extends Component {
  render() {
    return (
      <Layout>
      <div className="mb-5 pt-5 text-center">
        <h1 className="font-secondary text-primary">Where We're Registered</h1>
        {/* <p>We've registered with Zola and Amazon!<br/>For experience and traveling gifts, view our Zola list.<br/> For homegood gifts, view our Amazon list.</p> */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-6 mb-5">
              <h2>Zola</h2>
              <a href="https://www.zola.com/registry/ashleyandkeithseptember26" className="btn btn-primary btn-wide-sm btn-sm scroll">View Zola</a>
              <p>Preferred List</p>
            </div>
            <div className="col-md-6">
            <h2>Amazon</h2>
                <a href="https://www.amazon.com/wedding/ashley-brown-keith-knittel-port-clinton-september-2020/registry/2H97W6BGBVEA5" className="btn btn-outline-primary btn-wide-sm btn-sm scroll">View Amazon</a>
            </div>

          </div>
        </div>
      </div>
    </Layout>
    )
  }
}
