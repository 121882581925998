import React, { Component } from 'react';
import {Layout} from './components/Layout'
import './bootstrap.css';
import './Fonts.css';
import './magnific-popup.css';
import './Wedding.css';
import './App.css';


export default class Explore extends Component {
  render() {
    return (
      <Layout>
      <section id="friends" className="text-center">
      <div className="container spacer-double-lg">
        <div className="row justify-content-center">
          <div className="col">
            <div className=" mb-5 pb-5">
              <h1 className="display-4 mb-0 font-secondary">Things To Do</h1>
            </div>
          </div>
        </div>
        
        {/*Row*/}

        <div className="row justify-content-center">
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/cic.jpg')} alt="" />
              <h3 className="mb-0">CATAWBA ISLAND CLUB</h3>
              <p>4235 East Beach Club Road<br/>
                Port Clinton, OH 43452<br/>
                (419) 797-4424</p>
                <a href="http://www.cicclub.com/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/lighthouse.jpg')} alt="" />
              <h3 className="mb-0">MARBLEHEAD LIGHTHOUSE STATE PARK</h3>
              <p>110 Lighthouse Drive<br/>
                Lakeside Marblehead, OH 43440<br/>
                (419) 734-4424</p>
                <a href="http://parks.ohiodnr.gov/marbleheadlighthouse" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
        </div>

        
        <div className="row justify-content-center">
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/east.jpg')} alt="" />
              <h3 className="mb-0">EAST HARBOR STATE PARK</h3>
              <p>1169 North Buck Road<br/>
                Lakeside Marblehead, OH 43440<br/>
                (419) 734-4424</p>
                <a href="http://parks.ohiodnr.gov/eastharbor" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/nuf.jpg')} alt="" />
              <h3 className="mb-0">SHORE NUF CHARTERS</h3>
              <p>247 West Lakeshore Drive<br/>
              Port Clinton, OH 43452<br/>
              (419) 734-9999</p>
                <a href="https://shore-nuf.com/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
        </div>

        
        <div className="row justify-content-center">
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/jet.jpg')} alt="" />
              <h3 className="mb-0">JET EXPRESS</h3>
              <p>3 Monroe Street<br/>
              Port Clinton, OH 43452<br/>
              (800) 245-1538</p>
                <a href="http://www.jet-express.com/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/pib.jpg')} alt="" />
              <h3 className="mb-0">PUT-IN-BAY</h3>
              <p>Put-in-Bay, OH</p>
                <a href="https://www.visitputinbay.com/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
        </div>

        
        <div className="row justify-content-center">
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/kelleys.jpg')} alt="" />
              <h3 className="mb-0">KELLEYS ISLAND</h3>
              <p>Kelleys Island, OH 43438</p>
                <a href="http://kelleysisland.us/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/cp.jpg')} alt="" />
              <h3 className="mb-0">CEDAR POINT</h3>
              <p>1 Cedar Point Drive<br/>
              Sandusky, OH 44870<br/>
              (419) 627-2350</p>
                <a href="http://www.cedarpoint.com/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
        </div>

        
        <div className="row justify-content-center">
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/ma.jpg')} alt="" />
              <h3 className="mb-0">GIDEON OWEN WINE COMPANY</h3>
              <p>3845 East Wine Cellar Road<br/>
              Port Clinton, OH 43452<br/>
              (419) 797-4445</p>
                <a href="http://www.monamiwinery.com/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/dbh.jpg')} alt="" />
              <h3 className="mb-0">DOCK'S BEACH HOUSE RESTAURANT</h3>
              <p>252 West Lakeshore Drive<br/>
              Port Clinton, OH 43452<br/>
              (419) 732-6609</p>
                <a href="http://www.docksbeachhouse.com/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
        </div>

        
        <div className="row justify-content-center">
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/twin.jpg')} alt="" />
              <h3 className="mb-0">TWIN OAST BREWING</h3>
              <p>3630 Northeast Catawba Road<br/>
              Port Clinton, OH 43452<br/>
              (419) 573-6126</p>
                <a href="http://twinoast.com/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/bdd.jpg')} alt="" />
              <h3 className="mb-0">BROWN'S DAIRY DOCK</h3>
              <p>706 West Main Street<br/>
              Lakeside Marblehead, OH 43440<br/>
              (419) 798-5067</p>
                <a href="https://www.facebook.com/Browns-Dairy-Dock-131796494312/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
        </div>

        
        <div className="row justify-content-center">
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/slop.jpg')} alt="" />
              <h3 className="mb-0">SLOOPY'S PIZZA</h3>
              <p>226 Madison Street<br/>
              Port Clinton, OH 43452<br/>
              (419) 732-3553</p>
                <a href="http://www.sloopyspizza.com/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/berg.jpg')} alt="" />
              <h3 className="mb-0">BERGMAN ORCHARDS</h3>
              <p>600 Southeast Catawba Road<br/>
              Port Clinton, OH 43452<br/>
              (419) 734-6280</p>
                <a href="http://www.bergmanorchards.com/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
        </div>

        
        <div className="row justify-content-center">
          <div className="col-md-4 d-flex flex-column align-items-center">
            <div className="px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('./assets/tofts.jpg')} alt="" />
              <h3 className="mb-0">TOFT DAIRY ICE CREAM PARLOR</h3>
              <p>3717 Venice Road<br/>
              Sandusky, OH 44870<br/>
              (419) 625-5490</p>
                <a href="http://www.toftdairy.com/" className="btn btn-outline-secondary mr-2 btn-wide-sm btn-sm  scroll">View</a>
            </div>
          </div>
        </div>
        {/*End row*/}
        </div>
      </section>
      </Layout>
    )
  }
}
