import React, { Component } from 'react'

export default class Parents extends Component {
  render() {
    return (
      <section id="friends" className="text-center">
        <div className="container spacer-double-lg">
          <div className="row justify-content-center">
            <div className="col">
              <div className=" mb-5 pb-5">
                <h2 className="display-4 mb-0 font-secondary">Parents of the Bride</h2>
                {/* <p className="w-md-40 mb-0 mx-auto text-dark-gray opacity-8 ">We are lucky to have them</p> */}
              </div>
            </div>
          </div>
          
          {/*Row*/}
          <div className="row justify-content-center">
            <div className="col-md-8 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded img-fluid" src={require('../assets/maam.jpg')} alt="" />
                <h5 className="mt-2">Michael &amp; Ann Marie Brown</h5>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-8 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded img-fluid" src={require('../assets/kadb.jpg')} alt="" />
                <h5 className="mt-2">Fred &amp; Kimberly Jones</h5>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-8 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 mt-5 pb-4 ">
              <h2 className="display-4 mb-5 pb-2 font-secondary">Parents of the Groom</h2>
                <img className="rounded img-fluid" src={require('../assets/mad.jpg')} alt="" />
                <h5 className="mt-2">Kevin &amp; Susan Knittel</h5>
              </div>
            </div>
          </div>

          {/*End row*/}

        </div>

       
      </section>
    )
  }
}
