import React, { Component } from 'react';
import {Layout} from './components/Layout'
import './bootstrap.css';
import './Fonts.css';
import './magnific-popup.css';
import './Wedding.css';
import './App.css';


export default class Travel extends Component {
  render() {
    return (
      <Layout>
      <div className="mb-5 pt-5 text-center">
        <h1 className="font-secondary text-primary">Accommodations &amp; Travel</h1>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 px-5 mb-5">
              <h2>Fairfield Inn & Suites by Marriott Port Clinton Waterfront</h2>
              <h5>Hotel Block for Knittel Wedding!</h5>
              <p>1811 East Perry Street<br/>
                Port Clinton, OH 43452<br/>
                (419) 732-0007<br/><br/>
                There will be complimentary limo service from 230pm-Midnight to and from the venue for guests, running on the half hour.<br/><br/>
                Complimentary breakfast for all guests. <br/>
                Discounted wedding block rate.</p>
                <a href="https://redirect.viglink.com/?format=go&jsonp=vglnk_157595943674616&key=995c9fabde9eed146e66ff524e4335c9&libId=k3z8s8mx01020p2l000DA1iimcy4grm686&loc=https%3A%2F%2Fwww.zola.com%2Fwedding%2Fashleyandkeithseptember26%2Ftravel&v=1&out=https%3A%2F%2Fwww.marriott.com%2Fhotels%2Ftravel%2Ftolfc-fairfield-inn-and-suites-port-clinton-waterfront%2F%3Fscid%3Dbb1a189a-fec3-4d19-a255-54ba596febe2&title=Ashley%20Brown%20and%20Keith%20Knittel%27s%20Wedding%20Website&txt=View" className="btn btn-primary btn-wide-sm btn-sm scroll">View</a>
            </div>
            <div className="col-md-6 px-5 mb-5">
            <h2>Catawba Island Club</h2>
              <h5>Hotel at Wedding Venue</h5>
              <p>4235 East Beach Club Road<br/>
                Port Clinton, OH 43452<br/>
                (419) 797-4424<br/><br/>

                There will also be overnight dock spaces available for anyone wishing to bring their boats.</p>
                <p>To book, call and Reference Knittel Wedding</p>
                <a href="https://redirect.viglink.com/?format=go&jsonp=vglnk_157595948064517&key=995c9fabde9eed146e66ff524e4335c9&libId=k3z8s8mx01020p2l000DA1iimcy4grm686&loc=https%3A%2F%2Fwww.zola.com%2Fwedding%2Fashleyandkeithseptember26%2Ftravel&v=1&out=http%3A%2F%2Fwww.cicclub.com%2F&title=Ashley%20Brown%20and%20Keith%20Knittel%27s%20Wedding%20Website&txt=View" className="btn btn-primary btn-wide-sm btn-sm scroll">View</a>
            </div>

          </div>
        </div>
        <div className="container">
          <div className="row border-top mt-5 pt-5">
            <div className="col-md-6 mx-auto">
              <h2>Cleveland Hopkins International Airport</h2>
              <h5>Flight</h5>
              <p>1 hour 10 minutes from Port Clinton</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    )
  }
}
