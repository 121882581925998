import React from 'react'
import {Layout} from './components/Layout'
import './bootstrap.css';
import './Fonts.css';
import './magnific-popup.css';
import './Wedding.css';
import './App.css';
import PartyIntro from './components/PartyIntro';
import Parents from './components/Parents';

export const Party = () => (
    <Layout>
      <div className="mb-5 mt-5">
        {/* <h1 className="font-secondary text-primary">Party</h1> */}
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae velit vitae mi condimentum porta. Donec ornare nisl ac enim tempor accumsan. Quisque tempus nunc sit amet mi sodales fermentum. Mauris eleifend, massa eu imperdiet ultrices, odio lorem volutpat sapien, nec sollicitudin lacus orci ut nibh. Aenean tempor tellus ut lacinia convallis. Nullam eu feugiat libero. In nulla elit, porttitor a volutpat quis, luctus quis nisl. Vivamus malesuada id risus a consectetur. Mauris nec pulvinar dolor, at fringilla libero. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed eget nibh vitae ante sagittis posuere. Vestibulum posuere lectus sit amet felis feugiat, vel feugiat eros rutrum. Vivamus odio risus, viverra a lacus non, aliquet ullamcorper enim. Nunc pharetra tellus eget commodo tincidunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        <PartyIntro/>
        <Parents/>
      </div>
    </Layout>
)
