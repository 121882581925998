import React, { Component } from 'react'

export default class Gallery extends Component {
  render() {
    return (
      <section id="gallery" className="bg-secondary ">
        <div className="container spacer-double-lg">
          <div className="col-lg-11 mx-lg-auto">
            <div className="row justify-content-center card-gutters">
              <div className="col-7 mb-3">
                <a href={require('../assets/sydney-dip-kiss.jpg')} className="popup-image hover-effect d-block">
                  <img className="img-fluid" src={require('../assets/sydney-dip-kiss.jpg')} alt="" /> <span className="hover-effect-container">
                    <span className="hover-effect-icon has-svg">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 73.6404 64.5606" enableBackground="new 0 0 73.6404 64.5606" xmlSpace="preserve">
                        <g>
                          <path fill="#eaac52" d="M36.904,6.66L36.904,6.66C28.5329-1.7395,14.9401-1.8373,6.4502,6.4736
                                        c-8.5632,8.3827-8.5871,22.1694-0.2037,30.724l24.4097,24.9081c3.1797,3.2446,8.3876,3.2973,11.6323,0.1176l24.7745-24.2787
                                        c8.5602-8.3889,8.832-22.3842,0.4549-30.956C59.1567-1.5672,45.4453-1.7103,36.904,6.66z" />
                          <path fill="#FFFFFF" d="M44.695,28.8005c0-0.4883-0.3996-0.8879-0.8879-0.8879h-4.4394v-4.4394
                                        c0-0.4883-0.3996-0.8879-0.8879-0.8879h-2.9596c-0.4883,0-0.8879,0.3996-0.8879,0.8879v4.4394h-4.4394
                                        c-0.4883,0-0.8879,0.3995-0.8879,0.8879v2.9596c0,0.4883,0.3996,0.8879,0.8879,0.8879h4.4394v4.4394
                                        c0,0.4883,0.3996,0.8879,0.8879,0.8879h2.9596c0.4883,0,0.8879-0.3996,0.8879-0.8879V32.648h4.4394
                                        c0.4883,0,0.8879-0.3995,0.8879-0.8879V28.8005z" />
                        </g>
                      </svg>
                    </span>
                  </span>
                </a>
              </div>
              <div className="col-5 align-self-end mb-3">
                <a href={require('../assets/IMG_5708.jpg')} className="popup-image hover-effect d-block">
                  <img className="img-fluid" src={require('../assets/IMG_5708.jpg')} alt="" /> <span className="hover-effect-container">
                    <span className="hover-effect-icon has-svg">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 73.6404 64.5606" enableBackground="new 0 0 73.6404 64.5606" xmlSpace="preserve">
                        <g>
                          <path fill="#eaac52" d="M36.904,6.66L36.904,6.66C28.5329-1.7395,14.9401-1.8373,6.4502,6.4736
                                        c-8.5632,8.3827-8.5871,22.1694-0.2037,30.724l24.4097,24.9081c3.1797,3.2446,8.3876,3.2973,11.6323,0.1176l24.7745-24.2787
                                        c8.5602-8.3889,8.832-22.3842,0.4549-30.956C59.1567-1.5672,45.4453-1.7103,36.904,6.66z" />
                          <path fill="#FFFFFF" d="M44.695,28.8005c0-0.4883-0.3996-0.8879-0.8879-0.8879h-4.4394v-4.4394
                                        c0-0.4883-0.3996-0.8879-0.8879-0.8879h-2.9596c-0.4883,0-0.8879,0.3996-0.8879,0.8879v4.4394h-4.4394
                                        c-0.4883,0-0.8879,0.3995-0.8879,0.8879v2.9596c0,0.4883,0.3996,0.8879,0.8879,0.8879h4.4394v4.4394
                                        c0,0.4883,0.3996,0.8879,0.8879,0.8879h2.9596c0.4883,0,0.8879-0.3996,0.8879-0.8879V32.648h4.4394
                                        c0.4883,0,0.8879-0.3995,0.8879-0.8879V28.8005z" />
                        </g>
                      </svg>
                    </span>
                  </span>
                </a>
              </div>
              <div className="col-5">
                <a href={require('../assets/IMG_5259.jpg')} className="popup-image hover-effect d-block">
                  <img className="img-fluid" src={require('../assets/IMG_5259.jpg')} alt="" /> <span className="hover-effect-container">
                    <span className="hover-effect-icon has-svg">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 73.6404 64.5606" enableBackground="new 0 0 73.6404 64.5606" xmlSpace="preserve">
                        <g>
                          <path fill="#eaac52" d="M36.904,6.66L36.904,6.66C28.5329-1.7395,14.9401-1.8373,6.4502,6.4736
                                        c-8.5632,8.3827-8.5871,22.1694-0.2037,30.724l24.4097,24.9081c3.1797,3.2446,8.3876,3.2973,11.6323,0.1176l24.7745-24.2787
                                        c8.5602-8.3889,8.832-22.3842,0.4549-30.956C59.1567-1.5672,45.4453-1.7103,36.904,6.66z" />
                          <path fill="#FFFFFF" d="M44.695,28.8005c0-0.4883-0.3996-0.8879-0.8879-0.8879h-4.4394v-4.4394
                                        c0-0.4883-0.3996-0.8879-0.8879-0.8879h-2.9596c-0.4883,0-0.8879,0.3996-0.8879,0.8879v4.4394h-4.4394
                                        c-0.4883,0-0.8879,0.3995-0.8879,0.8879v2.9596c0,0.4883,0.3996,0.8879,0.8879,0.8879h4.4394v4.4394
                                        c0,0.4883,0.3996,0.8879,0.8879,0.8879h2.9596c0.4883,0,0.8879-0.3996,0.8879-0.8879V32.648h4.4394
                                        c0.4883,0,0.8879-0.3995,0.8879-0.8879V28.8005z" />
                        </g>
                      </svg>
                    </span>
                  </span>
                </a>
              </div>
              <div className="col-3">
                <a href={require('../assets/IMG_7168.jpg')} className="popup-image hover-effect d-block">
                  <img className="img-fluid" src={require('../assets/IMG_7168.jpg')} alt="" /> <span className="hover-effect-container">
                    <span className="hover-effect-icon has-svg">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 73.6404 64.5606" enableBackground="new 0 0 73.6404 64.5606" xmlSpace="preserve">
                        <g>
                          <path fill="#eaac52" d="M36.904,6.66L36.904,6.66C28.5329-1.7395,14.9401-1.8373,6.4502,6.4736
                                        c-8.5632,8.3827-8.5871,22.1694-0.2037,30.724l24.4097,24.9081c3.1797,3.2446,8.3876,3.2973,11.6323,0.1176l24.7745-24.2787
                                        c8.5602-8.3889,8.832-22.3842,0.4549-30.956C59.1567-1.5672,45.4453-1.7103,36.904,6.66z" />
                          <path fill="#FFFFFF" d="M44.695,28.8005c0-0.4883-0.3996-0.8879-0.8879-0.8879h-4.4394v-4.4394
                                        c0-0.4883-0.3996-0.8879-0.8879-0.8879h-2.9596c-0.4883,0-0.8879,0.3996-0.8879,0.8879v4.4394h-4.4394
                                        c-0.4883,0-0.8879,0.3995-0.8879,0.8879v2.9596c0,0.4883,0.3996,0.8879,0.8879,0.8879h4.4394v4.4394
                                        c0,0.4883,0.3996,0.8879,0.8879,0.8879h2.9596c0.4883,0,0.8879-0.3996,0.8879-0.8879V32.648h4.4394
                                        c0.4883,0,0.8879-0.3995,0.8879-0.8879V28.8005z" />
                        </g>
                      </svg>
                    </span>
                  </span>
                </a>
              </div>
              <div className="col-3">
                <a href={require('../assets/IMG_7400.jpg')} className="popup-image hover-effect d-block">
                  <img className="img-fluid" src={require('../assets/IMG_7400.jpg')} alt="" /> <span className="hover-effect-container">
                    <span className="hover-effect-icon has-svg">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 73.6404 64.5606" enableBackground="new 0 0 73.6404 64.5606" xmlSpace="preserve">
                        <g>
                          <path fill="#eaac52" d="M36.904,6.66L36.904,6.66C28.5329-1.7395,14.9401-1.8373,6.4502,6.4736
                                        c-8.5632,8.3827-8.5871,22.1694-0.2037,30.724l24.4097,24.9081c3.1797,3.2446,8.3876,3.2973,11.6323,0.1176l24.7745-24.2787
                                        c8.5602-8.3889,8.832-22.3842,0.4549-30.956C59.1567-1.5672,45.4453-1.7103,36.904,6.66z" />
                          <path fill="#FFFFFF" d="M44.695,28.8005c0-0.4883-0.3996-0.8879-0.8879-0.8879h-4.4394v-4.4394
                                        c0-0.4883-0.3996-0.8879-0.8879-0.8879h-2.9596c-0.4883,0-0.8879,0.3996-0.8879,0.8879v4.4394h-4.4394
                                        c-0.4883,0-0.8879,0.3995-0.8879,0.8879v2.9596c0,0.4883,0.3996,0.8879,0.8879,0.8879h4.4394v4.4394
                                        c0,0.4883,0.3996,0.8879,0.8879,0.8879h2.9596c0.4883,0,0.8879-0.3996,0.8879-0.8879V32.648h4.4394
                                        c0.4883,0,0.8879-0.3995,0.8879-0.8879V28.8005z" />
                        </g>
                      </svg>
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="curved-decoration ">
          <svg width="100%" height="100%" className="bg-primary-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2560 168.6227" enableBackground="new 0 0 2560 168.6227" xmlSpace="preserve">
            <g />
            <g>
              <path d="M2560,0c0,0-219.6543,165.951-730.788,124.0771c-383.3156-31.4028-827.2138-96.9514-1244.7139-96.9514
    c-212.5106,0-439,3.5-584.4982,1.5844l0,139.9126h2560V0z" />
            </g>
          </svg>
        </div>
      </section>
    )
  }
}
