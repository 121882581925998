import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Home';
import './bootstrap.css';
import './Fonts.css';
import './Wedding.css';
import './App.css';
import { NoMatch } from './NoMatch';
import { Navigation } from './components/Navigation';
import { Party } from './Party';
import Explore from './Explore';
import Travel from './Travel';
import Schedule from './Schedule';
import Registry from './Registry';

class App extends Component {
  render() {
    return (
      <React.Fragment>
      <div className="wrapper">
        <Navigation />
        <Router>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/schedule" component={Schedule}/>
            <Route exact path="/registry" component={Registry}/>
            <Route path="/travel" component={Travel}/>
            <Route path="/party" component={Party}/>
            <Route path="/explore" component={Explore}/>
            <Route component={NoMatch}/>
          </Switch>
        </Router>
      </div>

        {/* RSVP Block
        <section id="rsvp" className="bg-secondary spacer-one-top-lg o-hidden ">
          <div className="container spacer-one-bottom-lg">
            <div className="row justify-content-center">
              <div className="col">
                <div className=" mb-5 pb-5 text-center">
                  <h1 className="display-4 font-secondary">RSVP</h1>
                  <p className="w-md-40 mb-0 mx-auto text-dark-gray opacity-8">Let us know if you can make it</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-5 col-md-6">
                <form method="post">
                  <div className="form-group">
                    <label>Your Name</label>
                    <input name="name" type="text" className="form-control form-control-lg" placeholder="Your name" />
                  </div>
                  <div className="form-group">
                    <label>Email Address</label>
                    <input name="email" type="email" className="form-control form-control-lg" placeholder="Your email" />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group mb-1">
                        <label>Attending</label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group rounded bg-white p-2 border">
                        <div className="custom-control custom-radio">
                          <input type="radio" id="atttending-yes" name="atttending" className="custom-control-input" />
                          <label className="custom-control-label" htmlFor="atttending-yes">Yes, I will be there</label>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group rounded bg-white p-2 border">
                        <div className="custom-control custom-radio">
                          <input type="radio" id="atttending-no" name="atttending" className="custom-control-input" />
                          <label className="custom-control-label" htmlFor="atttending-no">Sorry, I can't come</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <button className="btn btn-lg btn-block btn-primary" type="submit">RSVP</button> <small className="mt-2 text-dark-gray opacity-8">You’ll recieve a confirmation email.</small>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        End RSVP Block */}
        
        {/* Pre Footer
          <footer className="spacer-double-lg bg-secondary">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="signature mb-3">
                  <h4 className="font-secondary text-primary">Adults Only</h4>
                  </div>
                  <p className="text-dark">Come get drunk and party.</p>
                </div>
                <div className="col-md-4">
                  <div className="signature mb-3">
                    <h4 className="font-secondary text-primary">Come hangout</h4>
                  </div>
                  <p className="text-dark">Come get drunk and party.</p>
                </div>
                <div className="col-md-4">
                  <div className="signature mb-3">
                  <h4 className="font-secondary text-primary">Enjoy your weekend</h4>
                  </div>
                  <p className="text-dark">Come get drunk and party.</p>
                </div>
              </div>
            </div>
          </footer>
        End Pre Footer */}
    
        <section className="footer-copyright spacer-double-sm bg-dark text-center">
          <p className="text-uppercase small text-muted mb-0">See you at the wedding!</p>
        </section>
      </React.Fragment>
    );
  }
}

export default App;
