import React, { Component } from 'react'

export default class Header extends Component {
  render() {
    return (
      <section className="gradient-overlay gradient-overlay-dark ">
        <img className="bg-image" src={require('../assets/sydney-dip-smile-center.jpg')} alt=""/>
        <div className="container">
            <div className="row min-vh-80 align-items-center z-index-2 position-relative text-center">
              <div className="col-12">
                  <h1 className="display-2 font-secondary text-primary mb-4">Ashley &amp; Keith</h1>
                  <h2 className="font-weight-300 text-light">Saturday, September 25th, 2021</h2>
                  <h4 className="font-weight-300 text-light mb-0">Catawba Island Club</h4>
                  <p className="font-weight-300 text-light">4235 East Beach Club Rd, Port Clinton, OH 43452</p> 
                  <a href="/schedule" className="btn btn-secondary btn-wide-sm btn-sm mr-3 scroll">Schedule</a>
                  <a href="/registry" className="btn btn-primary btn-wide-sm btn-sm scroll">Registry</a>
              </div>
            </div>
        </div>
        {/* Curved Right Start */}
        <div className="curved-decoration ">
            <svg width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2560 168.6227" enableBackground="new 0 0 2560 168.6227" xmlSpace="preserve">
              <g>
                <path d="M2560,0c0,0-219.6543,165.951-730.788,124.0771c-383.3156-31.4028-827.2138-96.9514-1244.7139-96.9514
          c-212.5106,0-439,3.5-584.4982,1.5844l0,139.9126h2560V0z" />
              </g>
            </svg>
          </div>
        {/* Curved Right End */}
      </section>
    )
  }
}
