import React, { Component } from 'react'

export default class PartyIntro extends Component {
  render() {
    return (
      <section id="friends" className="text-center">
        <div className="container spacer-double-lg">
          <div className="row justify-content-center">
            <div className="col">
              <div className=" mb-5 pb-5">
                <h1 className="display-4 mb-0 font-secondary">Our Wedding Party</h1>
                {/* <p className="w-md-40 mb-0 mx-auto text-dark-gray opacity-8 ">We are lucky to have them</p> */}
              </div>
            </div>
          </div>
          
          {/*Row*/}
          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/keith.jpg')} alt="" />
                <h5 className="mb-0">Keith Knittel</h5>
                <span className="small">Groom</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
              <img className="rounded-circle" src={require('../assets/ashley.jpg')} alt="" />
                <h5 className="mb-0">Ashley Brown</h5>
                <span className="small">Bride</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/ashleigh.jpg')} alt="" />
                <h5 className="mb-0">Ashleigh Ecclestone</h5>
                <span className="small">Matron of Honor</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/dre.jpg')} alt="" />
                <h5 className="mb-0">Andrea "Dre" Obregon</h5>
                <span className="small">Maid of Honor</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/brad.jpg')} alt="" />
                <h5 className="mb-0">Brad Knittel</h5>
                <span className="small">Best Man</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/brian.jpg')} alt="" />
                <h5 className="mb-0">Brian Seeley</h5>
                <span className="small">Best Man</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/kayla.jpg')} alt="" />
                <h5 className="mb-0">Kayla Tyree</h5>
                <span className="small">Bridesmaid</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/joe.jpg')} alt="" />
                <h5 className="mb-0">Joe Fesler</h5>
                <span className="small">Groomsman</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/kaylina.jpg')} alt="" />
                <h5 className="mb-0">Kaylina Schneider</h5>
                <span className="small">Bridesmaid</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/nikola.jpg')} alt="" />
                <h5 className="mb-0">Nikola Tumbas</h5>
                <span className="small">Groomsman</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/libbey.jpg')} alt="" />
                <h5 className="mb-0">Libbey Oreskovitch</h5>
                <span className="small">Bridesmaid</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/brenton.jpg')} alt="" />
                <h5 className="mb-0">Brenton Brada</h5>
                <span className="small">Groomsman</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/bridget.jpg')} alt="" />
                <h5 className="mb-0">Bridget Nehrenz</h5>
                <span className="small">Bridesmaid</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/doug.jpg')} alt="" />
                <h5 className="mb-0">Doug Nehrenz</h5>
                <span className="small">Groomsman</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/kenzi.jpg')} alt="" />
                <h5 className="mb-0">Kenzi Knight</h5>
                <span className="small">Bridesmaid</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/jake.jpg')} alt="" />
                <h5 className="mb-0">Jake MacDonald</h5>
                <span className="small">Groomsman</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/shannon.jpg')} alt="" />
                <h5 className="mb-0">Shannon Young</h5>
                <span className="small">Bridesmaid</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/eddie.jpg')} alt="" />
                <h5 className="mb-0">Eddie Verhovitz</h5>
                <span className="small">Groomsman</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/madison.jpg')} alt="" />
                <h5 className="mb-0">Madison Brown</h5>
                <span className="small">Junior Bridesmaid</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/yuna.jpg')} alt="" />
                <h5 className="mb-0">Yuna Banaska</h5>
                <span className="small">Flower Girl</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/ryan.jpg')} alt="" />
                <h5 className="mb-0">Ryan Owsiak</h5>
                <span className="small">Usher</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/tyler.jpg')} alt="" />
                <h5 className="mb-0">Tyler  Tyree</h5>
                <span className="small">Usher</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/eric.jpg')} alt="" />
                <h5 className="mb-0">Eric Leitner</h5>
                <span className="small">Usher</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/lola.jpg')} alt="" />
                <h5 className="mb-0">Lola Brown</h5>
                <span className="small">Dog of Honor</span>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className="px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/briane.jpg')} alt="" />
                <h5 className="mb-0">Brian Ecclestone</h5>
                <span className="small">DoH Escort</span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column align-items-center">
              <div className=" px-5 mb-4 pb-4 ">
                <img className="rounded-circle" src={require('../assets/vince.jpg')} alt="" />
                <h5 className="mb-0">Vince Frantz</h5>
                <span className="small">Officiant</span>
              </div>
            </div>
          </div>

          {/*End row*/}

        </div>

        {/*End container
        <div className="curved-decoration ">
          <svg width="100%" height="100%" className="bg-secondary-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2560 168.6227" enableBackground="new 0 0 2560 168.6227" xmlSpace="preserve">
            <g />
            <g>
              <path d="M2560,0c0,0-219.6543,165.951-730.788,124.0771c-383.3156-31.4028-827.2138-96.9514-1244.7139-96.9514
    c-212.5106,0-439,3.5-584.4982,1.5844l0,139.9126h2560V0z" />
            </g>
          </svg>
        </div>
        End container*/}
      </section>
    )
  }
}
